import { getAddress } from 'viem'
import { utils, BigNumberish } from 'ethers'
import trimEnd from 'lodash/trimEnd'

export const toChecksumAddress = (address: string) => {
  try {
    if (!address) {
      return ''
    }
    return getAddress(address)
  } catch {
    return address
  }
}

export const formatWei = (balance: BigNumberish, format = 18) => {
  if (!Number(balance)) return '0'
  return utils.formatUnits(balance, format)
}

export const formatWeiHuman = (
  balance: BigNumberish,
  format = 18,
): string => {
  if (!balance) return '0'
  const value = formatWei(balance, format)
  const [v1, v2 = '0'] = value.split('.')

  // 10000 -> 10 000
  const v11 = Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(BigInt(v1)).replace(/,/g, ' ')

  // 00111000 -> 00111
  const v22 = trimEnd(v2, '0')

  return v22 ? `${v11}.${v22}` : v11
}

export const formatWeiWithMin = (
  balance: BigNumberish,
  format = 18,
  settings: {
    compact?: boolean;
    min?: number | string;
    full?: boolean;
  } = {},
): string => {
  const {
    compact = false,
    min = 0.0001,
    full = false,
  } = settings

  if (!balance) return '0'
  const value = formatWei(balance, format)
  if (+value && +value < +min) return `< ${min}`

  const [v1, v2 = '0'] = value.split('.')

  // 100000 -> 10k
  if ((v1.length > 4 || +v2 < +min) && !full) {
    return Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      notation: compact ? 'compact' : void 0,
      style: 'decimal',
    }).format(BigInt(v1)).replace(/,/g, ' ')
  }

  const end = Math.min(min.toString().split('.')[1]?.length, Math.max(0, 6 - v1.length))

  // 10000 -> 10 000
  const v11 = Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(BigInt(v1)).replace(/,/g, ' ')

  // 00111000 -> 00111
  const v22 = trimEnd(v2.slice(0, end), '0')

  return v22 ? `${v11}.${v22}` : v11
}

export const hashRender = (hash: string, size = 4, separator = '...') => (
  hash.slice(0, size) + separator + hash.slice(-size)
)

export const sliceHash = (hash: string) => (
  `0x${hashRender(hash.slice(2))}`
)

export const addressToName = (address: string) => (
  `${address.slice(0, 4).toUpperCase()}...`
)
