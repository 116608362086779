import { toChecksumAddress } from '@/utils'

import {
  isMayBeENSAddress,
  sleep,
} from './helpers'


export const normalizeRecipients = async (
  recipients: [string, string][],
  resolveName: (string: string) => Promise<string | null>,
) => {
  const list: typeof recipients = []
  const { length } = recipients

  // eslint-disable-next-line no-unreachable-loop
  for (let index = 0; index < length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    if (index % 3000 === 0) await sleep(10)

    // supported empty lines: [''] -> ''
    // eslint-disable-next-line no-continue
    if (!recipients[index].toString()) continue

    const [rawAddress, ...arg] = recipients[index]

    let address = rawAddress
    if (isMayBeENSAddress(rawAddress)) {
      // eslint-disable-next-line no-await-in-loop
      const addressResolved = await resolveName(rawAddress)
      address = addressResolved || address
    } else {
      address = toChecksumAddress(rawAddress)
    }

    list.push([address, ...arg])
  }

  return list
}
