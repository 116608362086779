import groupBy from 'lodash/groupBy'
import { BigNumber } from 'ethers'

import {
  sleep,
  addressToName,
  formatWeiHuman,
  formatWeiWithMin,
  toChecksumAddress,
} from '@/utils'


const COUNT_PAGINATION_BEFORE_SLEEP = 10


type IErrorResponse = {
  status: string;
  result: string | null;
  message: string;
}

const handleError = async <T>(response: Response) => {
  if (!response.ok) {
    const error = new Error('Failed to fetch wallet tokens')
    return Promise.reject(error)
  }

  const json = await response.json() as T

  // Etherscan or Alchemy error response
  if ((json as unknown as IErrorResponse)?.status === '0') {
    const message = (
      (json as unknown as IErrorResponse).result
      || (json as unknown as IErrorResponse).message
    )
    const error = new Error(message)
    return Promise.reject(error)
  }

  return json
}

const getBalances = (balance: string, decimals: number) => {
  try {
    const balanceHuman = formatWeiHuman(
      balance,
      decimals,
    )

    const balanceHuman2 = formatWeiWithMin(
      balance,
      decimals,
      { compact: true },
    )

    return {
      balance,
      balanceHuman,
      balanceHuman2,
    }
  } catch {
    return {
      balance: '0', // needs for filtered
      balanceHuman: '0',
      balanceHuman2: '0',
    }
  }
}

export type ITokenData = {
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  balance: string;
  balanceHuman: string;
}

type IEthplorerTokenPrice = {
  rate: number;
}

type IEthplorerToken = {
  balance: number;
  rawBalance: string;
  tokenInfo: {
    address: string;
    decimals: string;
    name: string;
    symbol?: string;
    price: IEthplorerTokenPrice;
  };
  totalIn: number;
  totalOut: number;
}

type IEthplorerResponse = {
  ETH: {
    balance: number;
    rawBalance: string;
    price: IEthplorerTokenPrice;
  };
  address: string;
  countTxs: number;
  tokens?: IEthplorerToken[];
}

export const getTokensFromEthplorer = async (options: {
  path: string;
  chainId: number;
  signal?: AbortSignal;
}): Promise<ITokenData[]> => {
  const { path, chainId, signal } = options

  const result = await fetch(path, { signal })
    .then((response) => (handleError<IEthplorerResponse>(response)))

  if (!result?.tokens) return []

  const tokens = result.tokens.map((data) => {
    const { address, symbol, decimals } = data.tokenInfo
    const balances = getBalances(data.rawBalance, +decimals)

    return {
      address: toChecksumAddress(address),
      symbol: symbol || addressToName(address),
      decimals: +decimals,
      chainId,
      ...balances,
    }
  })

  return tokens?.filter((_) => (
    _.decimals
    && +_.balance > 0
  ))
}

type IBlockScoutResult = {
  balance: string;
  contractAddress: string;
  decimals: string;
  name: string;
  symbol?: string;
  type: 'ERC-20' | 'ERC-721';
}

type IBlockScoutResponse = {
  message: 'OK' | string;
  result?: IBlockScoutResult[];
  status: '0' | '1';
}

export const getTokensBlockScout = async (options: {
  path: string;
  chainId: number;
  signal?: AbortSignal;
}): Promise<ITokenData[]> => {
  const { path, chainId, signal } = options

  const result = await fetch(path, { signal })
    .then((response) => (handleError<IBlockScoutResponse>(response)))

  if (result?.message !== 'OK') return []

  const tokens = result.result
    ?.filter((_) => _.type === 'ERC-20')
    .map((_) => {
      const address = toChecksumAddress(_.contractAddress)
      const decimals = +_.decimals
      const balances = getBalances(_.balance, decimals)

      const symbol = (
        _.symbol
        || addressToName(address)
      )

      return {
        address,
        symbol,
        decimals,
        chainId,
        ...balances,
      }
    })

  return tokens?.filter((_) => (
    _.decimals
    && +_.balance > 0
  )) || []
}

type ICovalentResponseToken = {
  balance: string;
  balance_24h: string | null;
  contract_address: string;
  contract_decimals: number;
  contract_name: string;
  contract_ticker_symbol?: string;
  last_transferred_at: string | null;
  logo_url: string;
  nft_data: string | null;
  quote: number;
  quote_24h: number | null;
  quote_rate: number | null;
  quote_rate_24h: number | null;
  supports_erc: null;
  type: string;
}

type ICovalentResponse = {
  data: {
    address: string;
    chain_id: number;
    items: ICovalentResponseToken[];
    next_update_at: string;
    pagination: null;
    quote_currency: 'USD';
    updated_at: string;
  };
  error: boolean;
  error_code: number | null;
  error_message: number | null;
}

export const getTokensCovalent = async (options: {
  path: string;
  chainId: number;
  symbol: string;
  signal?: AbortSignal;
}): Promise<ITokenData[]> => {
  // eslint-disable-next-line object-curly-newline
  const { path, chainId, symbol: currencySymbol, signal } = options

  const result = await fetch(path, { signal })
    .then((response) => (handleError<ICovalentResponse>(response)))

  if (result?.error === true) return []

  const tokens = result.data.items
    ?.filter((_) => _.contract_ticker_symbol !== currencySymbol)
    .map((_) => {
      const decimals = +_.contract_decimals
      const address = toChecksumAddress(_.contract_address)
      const balances = getBalances(_.balance, decimals)

      const symbol = (
        _.contract_ticker_symbol
        || _.contract_name
        || addressToName(address)
      )

      return {
        address,
        symbol,
        decimals,
        chainId,
        ...balances,
      }
    })

  return tokens?.filter((_) => (
    _.decimals
    && +_.balance > 0
  )) || []
}

type IEtherscanResult = {
  blockHash: string;
  blockNumber: number;
  confirmations: string;
  contractAddress: string;
  cumulativeGasUsed: string;
  from: string;
  gas: string;
  gasPrice: string;
  gasUsed: string;
  hash: string;
  input: string;
  nonce: string;
  timeStamp: string;
  to: string;
  tokenDecimal: string;
  tokenName: string;
  tokenSymbol: string;
  transactionIndex: string;
  value: string;
}

type IEtherscanResponse = {
  message: 'OK' | 'NOTOK';
  result?: IEtherscanResult[];
}

const getTokensFromEtherscanRequest = (url: string, signal?: AbortSignal) => (
  fetch(url, { signal })
    .then((response) => (handleError<IEtherscanResponse>(response)))
)

const getTokensFromEtherscanRequestWithPagination = async (path: string, signal?: AbortSignal) => {
  let startblock = 0
  let page = 1
  let list: IEtherscanResult[] = []
  let result: IEtherscanResponse

  do {
    startblock = list[list.length - 1]?.blockNumber || 0
    // eslint-disable-next-line no-await-in-loop
    if (page % COUNT_PAGINATION_BEFORE_SLEEP === 0) await sleep(3000)
    const url = `${path}&startblock=${startblock}&endblock=latest`
    // eslint-disable-next-line no-await-in-loop
    result = await getTokensFromEtherscanRequest(url, signal)

    if (result?.message !== 'OK' || !result.result) return list
    list = list.concat(result.result)

    page += 1
  } while (result?.result.length >= 10_000)

  return list
}

export const getTokensFromEtherscan = async (options: {
  path: string;
  chainId: number;
  ethAccount: string;
  signal?: AbortSignal;
}): Promise<ITokenData[]> => {
  // eslint-disable-next-line object-curly-newline
  const { path, chainId, ethAccount, signal } = options

  const result = await getTokensFromEtherscanRequestWithPagination(path, signal)

  const account = ethAccount.toLowerCase()
  const groupTokens = groupBy(result, 'contractAddress')

  const tokens = Object.values(groupTokens).map((list) => {
    const [data] = list

    const balance = list.reduce((acc, _) => {
      const { value } = _
      const isTo = _.to?.toLowerCase() === account
      const isFrom = _.from?.toLowerCase() === account

      if (isTo && isFrom) return acc
      if (isTo) return acc.add(value)
      if (isFrom) return acc.sub(value)

      return acc
    }, BigNumber.from(0))

    const address = toChecksumAddress(data.contractAddress)
    const decimals = +data.tokenDecimal
    const balances = getBalances(balance.toString(), decimals)

    return {
      address,
      symbol: data.tokenSymbol || addressToName(address),
      decimals,
      chainId,
      ...balances,
    }
  })

  return tokens?.filter((_) => (
    _.decimals
    && +_.balance > 0
  )) || []
}

type Alchemy_getAssetTransfer = {
  asset: string;
  blockNum: string;
  category: 'erc20';
  erc721TokenId: null;
  erc1155Metadata: null;
  from: string;
  hash: string;
  rawContract: {
    value: string;
    address: string;
    decimal: string;
  };
  to: string;
  tokenId: null;
  value: number;
}

type Alchemy_getAssetTransferRpc = {
  id: number;
  jsonrpc: '2.0';
  error: {
    code: number;
    message: string;
  };
  result: never;
} | {
  id: number;
  jsonrpc: '2.0';
  error: never;
  result: {
    pageKey?: string;
    transfers: Alchemy_getAssetTransfer[];
  };
}

interface GetTokensFromAlchemyRequest {
  id?: number;
  (
    url: string,
    params:
      | {
        fromAddress: string;
        toAddress?: never;
        pageKey?: string;
      } | {
        fromAddress?: never;
        toAddress: string;
        pageKey?: string;
      },
    signal?: AbortSignal,
  ): Promise<Alchemy_getAssetTransferRpc['result']>;
}

const getTokensFromAlchemyRequest: GetTokensFromAlchemyRequest = async (url, params, signal) => {
  const id = getTokensFromAlchemyRequest.id || 0
  getTokensFromAlchemyRequest.id = id + 1

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const {
    fromAddress,
    toAddress,
    pageKey,
  } = params

  const body = JSON.stringify({
    jsonrpc: '2.0',
    id: getTokensFromAlchemyRequest.id,
    method: 'alchemy_getAssetTransfers',
    params: [
      {
        fromBlock: '0x0',
        excludeZeroValue: true,
        category: ['erc20'],
        fromAddress,
        toAddress,
        pageKey,
      },
    ],
  })

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    redirect: 'follow' as const,
    signal,
  }

  const response = await fetch(url, requestOptions)

  const result = await response.json() as Alchemy_getAssetTransferRpc
  if (result?.error) return Promise.reject(result?.error?.message || result?.error)
  return response.ok ? result.result : Promise.reject(response.statusText)
}

const getTokensFromAlchemyRequestWithPagination = async (
  url: string,
  params:
    | {
      fromAddress: string;
      toAddress?: never;
    } | {
      fromAddress?: never;
      toAddress: string;
    },
  signal?: AbortSignal,
) => {
  let i = 0
  let list: Alchemy_getAssetTransfer[] = []
  let result: Awaited<ReturnType<typeof getTokensFromAlchemyRequest>> = { transfers: [] }

  do {
    // eslint-disable-next-line no-await-in-loop
    if (++i % COUNT_PAGINATION_BEFORE_SLEEP === 0) await sleep(3000)
    const pageKey = result?.pageKey
    // eslint-disable-next-line no-await-in-loop
    result = await getTokensFromAlchemyRequest(url, { pageKey, ...params }, signal)
    list = list.concat(result.transfers)
  } while (result?.pageKey)

  return list
}

export const getTokensFromAlchemy = async (options: {
  path: string;
  chainId: number;
  ethAccount: string;
  signal?: AbortSignal;
}) => {
  // eslint-disable-next-line object-curly-newline
  const { path, chainId, ethAccount, signal } = options

  const [from, to] = await Promise.all([
    getTokensFromAlchemyRequestWithPagination(path, { fromAddress: ethAccount }, signal),
    getTokensFromAlchemyRequestWithPagination(path, { toAddress: ethAccount }, signal),
  ])

  const account = ethAccount.toLowerCase()
  const groupTokens = groupBy(to.concat(from), 'rawContract.address')

  const tokens = Object.values(groupTokens).map((list) => {
    const [data] = list

    const balance = list.reduce((acc, _) => {
      const { value } = _.rawContract
      const isTo = _.to.toLowerCase() === account
      const isFrom = _.from.toLowerCase() === account

      if (isTo && isFrom) return acc
      if (isTo) return acc.add(value)
      if (isFrom) return acc.sub(value)

      return acc
    }, BigNumber.from(0))

    const address = toChecksumAddress(data.rawContract.address)
    const decimals = +data.rawContract.decimal

    const balances = getBalances(balance.toString(), decimals)

    return {
      _origin: data,
      address,
      symbol: data.asset || addressToName(address),
      decimals,
      chainId,
      ...balances,
    }
  })

  return tokens?.filter((_) => (
    _.decimals
    && +_.balance > 0
  )) || []
}
