import type {
  IApiAirdropCreateRequest,
  IApiAirdropCreateResponse,
  IApiAirdropResponse,
  IApiAirdropPublicResponse,
  IApiAirdropPublicItemResponse,
  IApiAirdropForRecipient,
  IApiAirdropForOwner,
  IApiAirdropResponseWrapped,
  IApiAirdropResponseWithCountWrapped,
} from './types'

import { memoizeAsync, retry } from 'utils-decorators'
import { sentryLogger } from '@/services'
import { toChecksumAddress } from '@/utils'
import { ApiBaseService } from './ApiBaseService'
import { Transaction } from '@sentry/types'


export type IAirdropPrivate = IApiAirdropCreateResponse
export type IAirdropPublic = IApiAirdropPublicResponse
export type IAirdropPublicItem = IApiAirdropPublicItemResponse
export type IAirdropForRecipientItem = IApiAirdropForRecipient

const BASE_URL = process.env.VUE_APP_API_AIRDROP

const DEFAULT_HEADERS = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = <T extends IApiAirdropResponseWrapped<any>>(
  payload:| {
    data: T;
    error?: string;
    response: Response;
  } | {
    data?: T;
    error: string;
    response: Response;
  },
  transaction?: Transaction,
) => {
  const { data, response, error } = payload

  const hasError = !!(
    data?.error
    || error
    || (data?.error === 'false')
  )

  if (hasError) {
    const status = response.status === 200 ? 444 : response.status

    transaction
      ?.setData('error', data?.error || error)
      .setTag('api.error', true)
      .setStatus(response.statusText)
      .setHttpStatus(status)

    throw new Error(error || data?.error || 'Error')
  }

  transaction
    ?.setTag('api.error', false)
    .setHttpStatus(response.status)

  return payload.data as T
}

const handleErrorDown = (
  error: unknown,
  transaction?: Transaction,
) => {
  const status = (error as { status: number | string })?.status || 444

  transaction
    ?.setData('error', error)
    .setTag('api.error', true)

  if (typeof status === 'string') {
    transaction?.setStatus(status)
  } else {
    transaction?.setHttpStatus(status)
  }

  return Promise.reject(error)
}

const toQuery = (options: Record<string, string | number | null | undefined>) => {
  const result = Object.entries(options)
    .reduce((acc, [key, value]) => (
      value && value !== 0 ? acc.concat(`${key}=${value}`) : acc
    ), [] as string[])

  return result.length ? result.join('&') : ''
}

const RETRY_DATA = [1000, 3000]

export class ApiAirdropService extends ApiBaseService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected fetcher2<T extends IApiAirdropResponseWrapped<any>>(
    path: string,
    init: RequestInit,
    transaction?: Transaction,
  ) {
    const url = `${BASE_URL}/${path}`

    return this.fetcher<T>(url, init)
      .then((response) => (handleError<T>(response, transaction)))
      .catch((error) => (handleErrorDown(error, transaction)))
      .finally(() => transaction?.setData('body', init.body).finish())
  }

  public async create(params: IApiAirdropCreateRequest, ethAccount: string) {
    const path = 'airdrop/'

    const headers = {
      ...DEFAULT_HEADERS,
      'x-sender-address': ethAccount,
    }

    const body = JSON.stringify(params)

    const init = { method: 'POST', body, headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop',
      method: init.method,
      tags: {
        chainId: params.chainId,
        ethAccount: toChecksumAddress(ethAccount),
      },
      data: {
        ...params.token,
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IAirdropPrivate>
    return this.fetcher2<IResponse>(path, init, transaction)
  }

  @retry(RETRY_DATA)
  public async get(id: string, ethAccount: string) {
    const path = `airdrop/${id}`

    const headers = {
      ...DEFAULT_HEADERS,
      'x-sender-address': ethAccount,
    }

    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop/{id}',
      method: init.method,
      tags: {
        ethAccount: toChecksumAddress(ethAccount),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IApiAirdropResponse>
    return this.fetcher2<IResponse>(path, init, transaction)
  }

  public async update(id: string, data: Partial<IAirdropPrivate>, ethAccount: string) {
    const path = `airdrop/${id}`

    const headers = {
      ...DEFAULT_HEADERS,
      'x-sender-address': ethAccount,
    }

    const body = JSON.stringify(data)

    const init = { method: 'PUT', body, headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop/{id}',
      method: init.method,
      tags: {
        ethAccount: toChecksumAddress(ethAccount),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IAirdropPrivate>
    return this.fetcher2<IResponse>(path, init, transaction)
  }

  public async delete(id: string, ethAccount: string) {
    const path = `airdrop/${id}`

    const headers = {
      ...DEFAULT_HEADERS,
      'x-sender-address': ethAccount,
    }

    const init = { method: 'DELETE', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop/{id}',
      method: init.method,
      tags: {
        ethAccount: toChecksumAddress(ethAccount),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<boolean>
    return this.fetcher2<IResponse>(path, init, transaction)
  }

  @memoizeAsync(60 * 60 * 1000) // 1 hour
  @retry(RETRY_DATA)
  public async getByName(name: string, recipient: string | null) {
    let url = `airdrop/public/${name}`

    if (recipient) {
      url = `${url}?recipient=${toChecksumAddress(recipient)}`
    }

    const headers = DEFAULT_HEADERS
    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop/public/{name}',
      method: init.method,
      tags: {
        airdropName: name,
        ethAccount: recipient && toChecksumAddress(recipient),
        recipient: recipient && toChecksumAddress(recipient),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IApiAirdropPublicResponse>
    return this.fetcher2<IResponse>(url, init, transaction)
  }

  /*
  @memoizeAsync(10 * 60 * 1000) // 10min
  @retry(RETRY_DATA)
  public async getForOwner(name: string, owner: string) {
    const toChecksumAddress = await getToChecksumAddress()

    const url = `airdrop/${name}/owner/${toChecksumAddress(owner)}`

    const headers = {
      ...DEFAULT_HEADERS,
      'x-sender-address': owner,
    }

    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrop/{name}/owner/{owner}',
      method: init.method,
      tags: {
        airdropName: name,
        ethAccount: toChecksumAddress(owner),
        owner: toChecksumAddress(owner),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IApiAirdropForOwner>
    return this.fetcher2<IResponse>(url, init, transaction)
  }
  */

  @memoizeAsync(10 * 60 * 1000) // 10min
  @retry(RETRY_DATA)
  public async getRecipients(airdropName: string, options: {
    page: number;
    limit: number;
  }) {
    let url = `recipients/${airdropName}`

    const query = toQuery({
      ...options,
    })

    if (query) url = `${url}?${query}`

    const headers = DEFAULT_HEADERS
    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'recipient/{airdropName}',
      method: init.method,
      tags: {
        airdropName,
      },
    })

    type IResponse = IApiAirdropResponseWithCountWrapped<IApiAirdropForOwner['recipients']>
    return this.fetcher2<IResponse>(url, init, transaction)
  }

  @retry(RETRY_DATA)
  public async getAll(owner: string | null | undefined, options: {
    chainId?: number | null;
    page: number;
    limit: number;
  }) {
    let url = 'airdrops'

    const query = toQuery({
      owner: owner && toChecksumAddress(owner),
      ...options,
    })

    if (query) url = `${url}?${query}`

    const headers = DEFAULT_HEADERS
    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrops',
      method: init.method,
      tags: {
        owner: owner && toChecksumAddress(owner),
        ethAccount: owner && toChecksumAddress(owner),
      },
    })

    type IResponse = IApiAirdropResponseWithCountWrapped<IApiAirdropPublicItemResponse[]>
    return this.fetcher2<IResponse>(url, init, transaction)
  }

  @retry(RETRY_DATA)
  public async getAllForRecipient(recipient: string) {
    const url = `airdrops/recipient/${toChecksumAddress(recipient)}`

    const headers = DEFAULT_HEADERS
    const init = { method: 'GET', headers } as const

    const transaction = sentryLogger.trackApiRequest({
      base: BASE_URL,
      path: 'airdrops/recipient/{recipient}',
      method: init.method,
      tags: {
        recipient: toChecksumAddress(recipient),
      },
    })

    type IResponse = IApiAirdropResponseWrapped<IApiAirdropForRecipient[]>
    return this.fetcher2<IResponse>(url, init, transaction)
  }
}
