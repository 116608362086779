import { markRaw } from 'vue'
import type { Store } from 'vuex'

import { mapValues } from 'lodash'
import { BigNumber, constants } from 'ethers'
import { SendTransactionParameters, WalletClient } from 'viem'
import { cancelPrevious, memoizeAsync } from 'utils-decorators'
import type { ComposableFn, Context2 } from 'vuex-smart-module/lib'
import { TransactionStatus } from '@safe-global/safe-gateway-typescript-sdk'

import {
  Module,
  Actions,
  Getters,
  Mutations,
  createComposable,
} from 'vuex-smart-module'

import {
  sentryLogger,
  NetworkService,
  ApiAirdropService,
  ApproveTokenTypes,
  ERC20TokenService,
  MerkleTreeService,
  WalletTokensService,
} from '@/services'
import type { IAirdropPrivate } from '@/services'

import { DEFAULT_PAGE_LIMIT_FOR_BACKGROUND } from '@/utils/constants/params'
import { createTxApproveTokenDetails, createTxCreateAirdropDetails, normalizeRecipients } from '@/utils'

import { createSetState, isCancel } from './types'

import { WalletStoreModule } from './wallet'
import { TxHistoryStoreModule } from './txHistory'
import { MultisendRecipients } from '@/types/screening'

type S = MultisenderState
type G = MultisenderGetters
type M = MultisenderMutations
type A = MultisenderActions

type ITokenInfo = {
  address: string;
  symbol: string;
  decimals: number;

  chainId: number;

  balance: string | null;
  balanceHuman: string;

  allowanceForMultisender: string | null;
  allowanceForMultisenderHuman: string;
}

type IWalletToken = {
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;

  balance: string | null;
  balanceHuman: string | number;
}

export class MultisenderState {
  public airdropPrivate: IAirdropPrivate | null = null

  public recipients: [string, string][] = []

  public badAddresses: MultisendRecipients = {}

  public tokenInfo: ITokenInfo | null = null

  public walletTokens: IWalletToken[] = []

  public currentFee: string | null = null

  public createAirdropFee = '0'

  public approveTokenType = ApproveTokenTypes.default

  public airdropInfo = {
    email: '',
    website: '',
    twitter: '',
    telegram: '',
    description: '',
  }

  public chainId: number | null = null
}

class MultisenderGetters extends Getters<S> {
  public get isEnoughTokenBalance() {
    const { tokenInfo, airdropPrivate } = this.state
    if (!tokenInfo || !airdropPrivate) return false

    const balance = BigNumber.from(tokenInfo.balance || 0)
    const amount = BigNumber.from(airdropPrivate.recipientsAmount || 0)

    return balance.gte(amount) && !balance.isZero()
  }

  public get isEnoughApproveMultisender() {
    const { airdropPrivate, tokenInfo } = this.state
    if (!tokenInfo || !airdropPrivate) return false

    const allowance = BigNumber.from(tokenInfo.allowanceForMultisender || 0)
    const amount = BigNumber.from(airdropPrivate.recipientsAmount || 0)

    return allowance.gte(amount) && !allowance.isZero()
  }
}

class MultisenderMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }
}

class MultisenderActions extends Actions<S, G, M, A> {
  private walletStore!: Context2<typeof WalletStoreModule>

  private txHistoryStore!: Context2<typeof TxHistoryStoreModule>

  public $init(store: Store<unknown>) {
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
    this.txHistoryStore = TxHistoryStoreModule.context(store) as Context2<typeof TxHistoryStoreModule>
  }

  public async createAirdropPrivate(payload: {
    chainId: number;
    tokenAddress: string;
    ethAccount: string;
    recipients: S['recipients'];
  }) {
    const setState = createSetState<A>(this)
    // void this.actions.airdropDelete() TODO: do not delete airdrop in backend

    try {
      const results = await this.actions.getAirdropPrivate(payload)
      setState({ k: 'airdropPrivate', v: results.airdropPrivate })
      setState({ k: 'recipients', v: markRaw(results.recipients) })
    } catch (error) {
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return this.state.airdropPrivate
  }

 @cancelPrevious()
  public async getAirdropPrivate(payload:
    Parameters<A['createAirdropPrivate']>[0]) {
    const { chainId, tokenAddress, ethAccount } = payload

    const recipients = await normalizeRecipients(
      payload.recipients,
      NetworkService.resolveName.bind(NetworkService),
    )

    const tokenService = ERC20TokenService.buildClass(tokenAddress, chainId)
    await tokenService.updateMeta()

    const token = {
      address: tokenService.address,
      symbol: tokenService.symbol,
      decimals: tokenService.decimals,
    }

    const api = new ApiAirdropService()
    const params = { chainId, token, recipients }

    const [response] = await Promise.all([
      api.create(params, ethAccount || token.address),
      this.actions.updateTokenInfo({ chainId, address: token.address }),
    ])

    return {
      airdropPrivate: response?.data,
      recipients: markRaw(recipients),
    }
  }

 public async putAirdropPrivate(payload: {
    txHash?: string;
    isPublished?: boolean;
  }) {
   const setState = createSetState<A>(this)
   const { txHash } = payload

   const { airdropPrivate } = this.state
   if (!airdropPrivate) return null

   if (airdropPrivate.isPublished && !txHash) {
     // only txHash may be change when airdrop is published
     return null
   }

   const api = new ApiAirdropService()
   const { ethAccount } = this.walletStore.state

   const airdropInfo = mapValues(
     this.state.airdropInfo,
     (v) => v?.toString().trim() || null,
   ) as S['airdropInfo']

   // txHash need send only it!!! for update after isPublished
   const data = airdropPrivate.isPublished
     ? { txHash }
     : { ...payload, ...airdropInfo }

   const address = ethAccount || airdropPrivate.token.address

   try {
     // eslint-disable-next-line no-underscore-dangle
     const results = await api.update(airdropPrivate._id, data, address)
     setState({ k: 'airdropPrivate', v: results.data })
   } catch (error) {
     if (!isCancel(error)) {
       sentryLogger.capture(error)
       return Promise.reject(error)
     }
   }

   return this.state.airdropPrivate
 }

 public async updateAirdropPrivate() {
   const setState = createSetState<A>(this)
   const { airdropPrivate } = this.state
   if (!airdropPrivate) return false

   const api = new ApiAirdropService()
   const { token } = airdropPrivate
   const { ethAccount } = this.walletStore.state

   // eslint-disable-next-line no-underscore-dangle
   const response = await api.get(airdropPrivate._id, ethAccount || token.address)
     .catch(() => null)

   if (!response?.data) {
     setState({ k: 'airdropPrivate', v: null })
     setState({ k: 'tokenInfo', v: null })
     setState({ k: 'recipients', v: [] })

     return false
   }

   const airdropPrivateNew = {
     ...airdropPrivate,
     ...response.data,
   }

   setState({ k: 'airdropPrivate', v: airdropPrivateNew })

   await this.actions.updateTokenInfo({
     chainId: airdropPrivateNew.chainId,
     address: airdropPrivateNew.token.address,
   })

   return !!response?.data
 }

 public async updateTokenInfo(payload: {
    address?: string | null;
    chainId?: number | null;
  }) {
   const setState = createSetState<A>(this)
   const { address, chainId } = payload

   if (!address || !chainId) {
     setState({ k: 'tokenInfo', v: null })
     return
   }

   try {
     const tokenInfo = await this.actions.getTokenInfo({ address, chainId })
     setState({ k: 'tokenInfo', v: tokenInfo })
   } catch (error) {
     if (!isCancel(error)) {
       sentryLogger.capture(error)
       // eslint-disable-next-line consistent-return
       return Promise.reject(error)
     }
   }
 }

 @memoizeAsync(2000)
 @cancelPrevious()
 public async getTokenInfo(payload: {
    address: string;
    chainId: number;
  }) {
   const { address, chainId } = payload as Required<typeof payload>
   const { ethAccount } = this.walletStore.state

   const tokenService = ERC20TokenService.buildClass(address, chainId, ethAccount)
   await tokenService.updateData()

   const tokenInfo = {
     chainId,

     address: tokenService.address,
     symbol: tokenService.symbol,
     decimals: tokenService.decimals,

     balance: tokenService.balance,
     balanceHuman: tokenService.balanceHuman,

     allowanceForMultisender: tokenService.allowanceForMultisender,
     allowanceForMultisenderHuman: tokenService.allowanceForMultisenderHuman,
   }

   return tokenInfo
 }

 public async updateApproveForMultisenderType(payload: {
    address?: string | null;
    chainId?: number | null;
  }) {
   const setState = createSetState<A>(this)
   const { address, chainId } = payload

   if (!address || !chainId) {
     setState({ k: 'approveTokenType', v: ApproveTokenTypes.default })
     return
   }

   try {
     const type = await this.actions.getApproveForMultisenderType({ address, chainId })
     setState({ k: 'approveTokenType', v: type })
   } catch (error) {
     if (!isCancel(error)) {
       sentryLogger.capture(error)
       // eslint-disable-next-line consistent-return
       return Promise.reject(error)
     }
   }
 }


 @memoizeAsync(2000)
 @cancelPrevious()
 public async getApproveForMultisenderType(payload: {
    address: string;
    chainId: number;
  }) {
   const { address, chainId } = payload as Required<typeof payload>
   const { ethAccount } = this.walletStore.state

   const tokenService = ERC20TokenService.buildClass(address, chainId, ethAccount)
   const type = await tokenService.getApproveForMultisenderType()

   return type || ApproveTokenTypes.default
 }

 public async updateWalletTokens(payload: {
    chainId: number;
  }) {
   const setState = createSetState<A>(this)

   try {
     const walletTokens = await this.actions.getWalletTokens(payload)
     setState({ k: 'walletTokens', v: markRaw(walletTokens) })
   } catch (error) {
     if (!isCancel(error)) {
       sentryLogger.capture(error)
       return Promise.reject(error)
     }
   }

   return this.state.walletTokens
 }


 @cancelPrevious()
 public async getWalletTokens(payload:
    Parameters<A['updateWalletTokens']>[0]) {
   const { chainId } = payload
   const { ethAccount } = this.walletStore.state

   if (!chainId || !ethAccount) {
     return []
   }

   const walletTokensService = WalletTokensService.buildClass(chainId, ethAccount)
   void WalletTokensService.cancelLast()

   try {
     const tokens = await walletTokensService.getTokens()
     return tokens
   } catch (error) {
     sentryLogger.capture(error)
     return []
   }
 }

 public cancelGetWalletTokens() {
   void WalletTokensService.cancelLast()
 }

 public async updateAirdropRecipients(payload: { airdropName: string }) {
   const setState = createSetState<A>(this)
   const { airdropName } = payload

   let publicName: string | undefined
   let recipients: MultisenderState['recipients'] = []
   let page = 0

   do {
     try {
       page += 1
       const options = { airdropName, page, limit: DEFAULT_PAGE_LIMIT_FOR_BACKGROUND }
       // eslint-disable-next-line no-await-in-loop
       const results = await this.actions.getAirdropRecipients(options)
       recipients = recipients.concat(results.data || [])
       setState({ k: 'recipients', v: markRaw(recipients) })

       if (recipients.length >= results.count) return true
     } catch (error) {
       if (!isCancel(error)) {
         sentryLogger.capture(error)
         return Promise.reject(error)
       }

       return false
     }

     publicName = this.state.airdropPrivate?.publicName
   } while (airdropName === publicName)

   return true
 }

 @cancelPrevious()
 public async getAirdropRecipients(payload:{
    airdropName: string;
    limit: number;
    page: number;
  }) {
   const { airdropName, limit, page } = payload

   const api = new ApiAirdropService()
   const response = await api.getRecipients(airdropName, { limit, page })
   return response
 }

 public async approveTokenForMultisender({ amount, client }: {
   amount?: string;
   client: WalletClient;
  }) {
   const { tokenInfo, airdropPrivate } = this.state

   const { ethAccount } = this.walletStore.state
   const { address, chainId } = tokenInfo || {}

   if (!chainId || !address || !tokenInfo || !ethAccount) return null

   const tokenService = ERC20TokenService.buildClass(address, chainId, ethAccount)

   const currentAmount = amount || constants.MaxUint256.toString()

   const txData = await tokenService.approveForMultisender(
     currentAmount,
     this.walletStore.getters.isInjectedWallet,
   )
   const hash = await client.sendTransaction(txData as SendTransactionParameters)

   const details = createTxApproveTokenDetails(tokenInfo, currentAmount, airdropPrivate?.publicName)

   const isSafeTx = await this.walletStore.actions.detectGnosisSafeTx({ hash, chainId })

   if (isSafeTx && airdropPrivate?.token) {
     const tx2 = await this.txHistoryStore.actions.addGnosisTx({
       hash,
       details,
       chainId,
       multisigAddress: ethAccount,
       token: airdropPrivate.token.address,
       status: TransactionStatus.AWAITING_CONFIRMATIONS,
     })
     return tx2
   }

   const tx = await this.txHistoryStore.actions.handleWatchTxData({ txHash: hash, chainId })
   const tx2 = this.txHistoryStore.actions.add({ tx, chainId, details })

   return tx2
 }

 public async updateCreateAirdropFee() {
   const setState = createSetState<A>(this)
   const { airdropPrivate } = this.state

   if (!airdropPrivate) {
     setState({ k: 'createAirdropFee', v: '0' })
     setState({ k: 'currentFee', v: null })
     return
   }

   const options = {
     chainId: airdropPrivate.chainId,
     address: airdropPrivate.token.address,
   }

   try {
     const data = await this.actions.getCreateAirdropFee(options)
     setState({ k: 'createAirdropFee', v: data.createAirdropFee })
     setState({ k: 'currentFee', v: data.currentFee })
   } catch (error) {
     if (!isCancel(error)) {
       sentryLogger.capture(error)
       // eslint-disable-next-line consistent-return
       return Promise.reject(error)
     }
   }
 }

 @memoizeAsync(2000)
 @cancelPrevious()
 public async getCreateAirdropFee(payload: {
    chainId: number;
  }) {
   const merkleTreeService = new MerkleTreeService(payload.chainId)
   const { airdropPrivate } = this.state
   const { ethAccount } = this.walletStore.state

   if (!airdropPrivate || !ethAccount) {
     return {
       createAirdropFee: '0',
       currentFee: null,
     }
   }

   const params = {
     publicName: airdropPrivate.publicName,
     treeRoot: airdropPrivate.treeRoot,
     tokenAddress: airdropPrivate.token.address,
     total: airdropPrivate.recipientsAmount,
     ethAccount,
   }

   await merkleTreeService.updateCreateAirdropFee(params)

   return {
     createAirdropFee: merkleTreeService.createAirdropFee || '0',
     currentFee: merkleTreeService.currentFee || '0',
   }
 }

 public updateBadAddresses(payload: S['badAddresses']) {
   const setState = createSetState<A>(this)
   setState({ k: 'badAddresses', v: payload })
 }

 public cleanBadAddressesInfo() {
   const setState = createSetState<A>(this)
   setState({ k: 'badAddresses', v: {} })
 }

 public updateAirdropInfo(payload: S['airdropInfo']) {
   const setState = createSetState<A>(this)
   setState({ k: 'airdropInfo', v: payload })
 }


 // eslint-disable-next-line @typescript-eslint/require-await
 private async prepareCreateAirdrop() {
   const { airdropPrivate } = this.state
   const { ethAccount } = this.walletStore.state

   const { chainId } = airdropPrivate || {}

   if (!ethAccount || !airdropPrivate || !chainId) return null

   const args = [
     airdropPrivate.publicName,
     airdropPrivate.treeRoot,
     airdropPrivate.token.address,
     airdropPrivate.recipientsAmount,
     ethAccount,
   ] as const

   const merkleTreeService = new MerkleTreeService(chainId)

   return {
     args,
     chainId,
     ethAccount,
     airdropPrivate,
     merkleTreeService,
   }
 }

 public async checkCreateAirdrop() {
   const prepare = await this.actions.prepareCreateAirdrop()
   if (!prepare) return prepare

   const { args, merkleTreeService } = prepare

   return merkleTreeService.checkCreateAirdrop(...args)
 }

 public async createAirdrop(client: WalletClient) {
   const prepare = await this.actions.prepareCreateAirdrop()
   if (!prepare) return prepare

   // eslint-disable-next-line object-curly-newline
   const { args, chainId, airdropPrivate, merkleTreeService, ethAccount } = prepare

   const txData = await merkleTreeService.createAirdrop(args, this.walletStore.getters.isInjectedWallet)
   const hash = await client.sendTransaction(txData as SendTransactionParameters)

   const details = createTxCreateAirdropDetails(airdropPrivate.publicName)

   const isSafeTx = await this.walletStore.actions.detectGnosisSafeTx({ hash, chainId })

   if (isSafeTx) {
     const tx2 = await this.txHistoryStore.actions.addGnosisTx({
       hash,
       details,
       chainId,
       token: airdropPrivate.token.address,
       multisigAddress: ethAccount,
       status: TransactionStatus.AWAITING_CONFIRMATIONS,
     })
     return tx2
   }

   const tx = await this.txHistoryStore.actions.handleWatchTxData({ txHash: hash, chainId })
   const tx2 = this.txHistoryStore.actions.add({ tx, chainId, details })
   return tx2
 }

 public cleanupDistributionData() {
   const setState = createSetState<A>(this)
   const initialState = new MultisenderState()

   setState({ k: 'tokenInfo', v: initialState.tokenInfo })
   setState({ k: 'recipients', v: initialState.recipients })
   setState({ k: 'airdropInfo', v: initialState.airdropInfo })
   setState({ k: 'airdropPrivate', v: initialState.airdropPrivate })
 }

 public airdropDelete() {
   const { airdropPrivate } = this.state
   if (!airdropPrivate || airdropPrivate.isPublished) return

   const ethAccount = (
     this.walletStore.state.ethAccount
      || airdropPrivate.token.address
   )

   const api = new ApiAirdropService()
   // eslint-disable-next-line no-underscore-dangle
   void api.delete(airdropPrivate._id, ethAccount)
 }

 public cleanup() {
   const setState = createSetState<A>(this)
   const stateInitial = new MultisenderState()

   Object.entries(stateInitial).forEach((data) => {
     const [k, v] = data as [keyof MultisenderState, MultisenderState[keyof MultisenderState]]
     setState({ k, v } as Parameters<MultisenderMutations['setState']>[0])
   })
 }
}

const storeModule = new Module({
  namespaced: true,
  state: MultisenderState,
  getters: MultisenderGetters,
  mutations: MultisenderMutations,
  actions: MultisenderActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as MultisenderStoreModule,
  useStore as useMultisenderStoreModule,
}
