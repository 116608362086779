import { Plugin } from 'vue'
import { IInitPlugin } from './types'

import { initPlugin as initPluginQuery } from './query'
import { initPlugin as initPluginWagmi } from './wagmi'
import { initPlugin as initPluginSentry } from './sentry'
import { initPlugin as initPluginVueI18n } from './vue-i18n'
import { initPlugin as initPluginSvgSprites } from './svg-sprites'
import { initPlugin as initPluginGtag } from './gtag'

const install: IInitPlugin<void[]> = (app, options) => {
  const promises = [
    initPluginSvgSprites(app, options),
    initPluginVueI18n(app, options),
    initPluginWagmi(app, options),
    initPluginQuery(app, options),
    initPluginGtag(app, options),
  ]

  if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_RELEASE) {
    const promiseSentry = initPluginSentry(app, options)
    promises.push(promiseSentry)
  }

  if (process.env.NODE_ENV === 'production') {
    const promiseGtag = initPluginGtag(app, options)
    promises.push(promiseGtag)
  }

  return Promise.all(promises)
}

export const initPlugins: Plugin = {
  install,
}
