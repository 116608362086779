import { StorageService } from './StorageService'
import { STORAGE_NAME } from '@/utils/constants/params'


export * from './ga'
export * from './ApiService'
export * from './ERC20TokenService'
export * from './FetchContractEvents'
export * from './FileParserService'
export * from './MerkleTreeService'
export * from './Multicall3Service'
export * from './NetworkService'
export * from './SentryLogger'
export * from './ServiceWorkerService'
export * from './ValidatorService'
export * from './WaitTxReceipt'
export * from './WalletTokensService'

export * from './BlockBodyService'
export * from './StorageService'


export const storages = {
  locale: new StorageService<string>('locale', STORAGE_NAME),
  rpcUrls: new StorageService<Record<number, string>>('rpcUrls', STORAGE_NAME),
  detectFirstTab: new StorageService<number>('detectFirstTab', STORAGE_NAME),
}
