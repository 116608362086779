import { event } from 'vue-gtag'

function sendEvent(category: string) {
  const isDev = process.env.NODE_ENV === 'development'
  const eventTracker = (action: string, label?: string) => {
    if (!isDev) {
      event(category, { action, label })
    }
  }
  return eventTracker
}

export const ga = {
  sendEvent,
}
